import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				Часті запитання | Автомийка на Макарова
			</title>
			<meta name={"description"} content={"Є питання? У нас є відповіді! Ось добірка найпоширеніших запитань, які допоможуть вам зрозуміти більше про послуги автомийки та автосервісу Автомийка на Макарова."} />
			<meta property={"og:title"} content={"Часті запитання | Автомийка на Макарова"} />
			<meta property={"og:description"} content={"Є питання? У нас є відповіді! Ось добірка найпоширеніших запитань, які допоможуть вам зрозуміти більше про послуги автомийки та автосервісу Автомийка на Макарова."} />
			<meta property={"og:image"} content={"https://jedis-ua.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://jedis-ua.com/img/88.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://jedis-ua.com/img/88.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://jedis-ua.com/img/88.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://jedis-ua.com/img/88.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://jedis-ua.com/img/88.png"} />
			<meta name={"msapplication-TileImage"} content={"https://jedis-ua.com/img/88.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-9">
			<Text margin="0px 0px 70px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1" sm-margin="0px 0px 50px 0px">
				Часті запитання
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="1fr"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
				flex-direction="column"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Що відрізняє Автомийка на Макарова від інших автомийок?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Автомийка на Макарова вирізняється своєю прихильністю до якості, екологічно чистих продуктів та персоналізованого обслуговування. Ми зосереджуємося на естетиці та довговічності вашого автомобіля.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Чи можете ви впоратися з розкішними або екзотичними автомобілями?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Безумовно! Ми маємо досвід роботи з різноманітними автомобілями, включаючи розкішні та екзотичні моделі. Ми адаптуємо наші послуги до конкретних потреб та вимог до догляду за кожним автомобілем.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Чи використовуєте ви екологічно чисті миючі засоби?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Так, ми ставимо екологію на перше місце. Наші миючі засоби є екологічно чистими і розроблені таким чином, щоб забезпечити чудову чистоту без шкоди для планети.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Скільки часу триває типова послуга з дезактивації?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Тривалість залежить від обраної послуги. Стандартне миття може зайняти близько 30 хвилин, тоді як повне очищення може тривати кілька годин. Ми зосереджуємося на якості та ретельності.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Чи потрібен попередній запис?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Хоча ми часто приймаємо відвідувачів без попереднього запису, ми рекомендуємо заздалегідь домовлятися про час для своєчасного обслуговування, особливо для детальної роботи.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Чи надаєте ви якісь гарантії на свої послуги?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Так, задоволеність клієнтів є нашим пріоритетом. Якщо ви не задоволені нашим сервісом, ми все виправимо.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});